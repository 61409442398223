// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { graphql } from 'gatsby'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Breadcrumbs from '../components/breadcrumbs'
import '../components/breadcrumbs/style.less'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import Link from '../components/link'
import '../components/link/style.less'

import getRandomArbitraryInt from '../methods/getRandomArbitraryInt'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'Blog',
  slug: '/blog',
  abstract:
    'A list of our upcoming and past events held in the community including presentations of our project, launch events as well as the Unfold Your Skills workshops.',
  breadcrumbs: [
    { title: 'Homepage', slug: '' },
    { title: 'Blog', slug: '/blog' },
  ],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query BlogPageQuery {
    allBlogJson {
      edges {
        node {
          title
          routeSlug
          publishedTimestamp
          shortAbstract
          cover {
            childImageSharp {
              gatsbyImageData(
                width: 600
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = ({ data, mediaState, ...restProps }) => {
  const {
    allBlogJson: { edges },
  } = data
  const nodes = map(edges, 'node')
  const orderedNodes = orderBy(nodes, ['publishedTimestamp'], ['desc'])

  return (
    <StandardPageWrapper pageSchema={pageSchema} {...restProps}>
      <div className="container">
        <Breadcrumbs breadcrumbs={pageSchema.breadcrumbs} />
        <h1>Blog</h1>
        <p>
          Guest contributions, articles, and more. Artwork for Aura Project by
          Emanuele Scanziani. Do you wish you contribute an article? Please
          submit your contribution to{' '}
          <Link to="mailto:admin@auranetwork.app">admin@auranetwork.app</Link>.
        </p>
        <Row
          gutter={[
            { xs: 12, sm: 16, md: 20, lg: 24, xl: 28, xxl: 32 },
            { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
          ]}
        >
          {map(orderedNodes, (node, index) => (
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={8}
              xl={8}
              xxl={8}
              style={{
                marginBottom: '1.1rem',
              }}
            >
              <Link
                className="post grid-resource"
                style={{ padding: 'unset' }}
                to={node.routeSlug}
              >
                <h3>{node.title}</h3>
                <GatsbyImage
                  className="cover"
                  style={{ minHeight: '6rem' }}
                  image={getImage(node.cover)}
                  durationFadeIn={getRandomArbitraryInt(150, 450)}
                />
              </Link>
            </Col>
          ))}
        </Row>
      </div>
    </StandardPageWrapper>
  )
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    mediaState: state.mediaState,
  })
  // dispatch => ({
  //   updateWebsiteTheme(payload) {
  //     dispatch(updateWebsiteTheme(payload))
  //   },
  //   updateHeaderState(payload) {
  //     dispatch(updateHeaderState(payload))
  //   },
  // })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedPage = compose(
  withState // Add state
)(Page)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedPage
